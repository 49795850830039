import React from "react"
/** @jsx jsx */
import { jsx, useColorMode, Link as LinkThemed } from "theme-ui"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'

const ChangeTheme = ({ showInMobile = true }) => {
    const [colorMode, setColorMode] = useColorMode();
  
    const newColorMode = colorMode === 'light'
        ? 'dark'
        : 'light'

    return (
        <button
            aria-label="mudar-tema"
            sx={{
                background: 'none',
                color: 'inherit',
                border: 'none',
                padding: 0,
                font: 'inherit',
                cursor: 'pointer',
                outline: 'inherit',
                width: '36px',
                height: '36px',
                justifySelf: 'end',
                display: showInMobile ? 'none' : 'inherit',
                '@media (max-width: 768px)': {
                    display: showInMobile ? 'inherit' : 'none'
                }
        }}>
            <FontAwesomeIcon
                onClick={e => {
                setColorMode(newColorMode)
                }}
                size="2x"
                icon={colorMode === 'light' ? faSun : faMoon}
                sx={{ 
                color: 'text',
                '&:hover': {
                    color: 'primary'
                }
                }}
            />
        </button>
    )
}

export default ChangeTheme