import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

const Hamburger = ({ open }) => {
    return (
        <div sx={{
            backgroundColor: 'primary',
            width: '30px',
            height: '3px',
            transition: 'all .3s linear',
            alignSelf: 'center',
            position: 'relative',
            transform: `${open ? "rotate(-45deg)" : "inherit"}`,

            '::before, ::after': {
                width: '30px',
                height: '3px',
                backgroundColor: 'primary',
                content: '""',
                position: 'absolute',
                transition: 'all 0.3s linear',
            },

            '::before': {
                transform: `${open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"}`,
                top: '-10px',
            },
            
            '::after': {
                opacity: `${open ? "0" : "1"}`,
                transform: `${open ? "rotate(90deg) " : "rotate(0deg)"}`,
                top: '10px'
            }
        }}/>
    )
}

export default Hamburger