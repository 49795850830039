import React, { useState } from "react"
import NavbarLinks from "./NavbarLinks"
import Logo from "./Logo"
import ChangeTheme from "./ChangeTheme"
import Hamburger from "./Hamburger";
import Navbox from "./Navbox"

import styled from '@emotion/styled'

/** @jsx jsx */
import { jsx } from "theme-ui"

const Navigation = styled.nav`
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 800px;

    @media (max-width: 768px) {
        height: 10vh;
    }
`

const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 0 2vw;
  margin: 0 1vw;

  @media (max-width: 768px) {
    display: flex;
  }
`

const Navbar = ({ siteTitle }) => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  return (
    <Navigation
        sx={{
            zIndex: 2,
            backgroundColor: 'background',
            padding: 3,
            marginBottom: 4,
            '@media (max-width: 768px)': {
              marginBottom: 2
            }
        }}
    >
      <Logo siteTitle={siteTitle}/>
      <div sx={{ display: 'flex', alignItems: 'center' }}>
        <ChangeTheme showInMobile={true}/>
        <Toggle
            navbarOpen={navbarOpen}
            onClick={() => setNavbarOpen(!navbarOpen)}
        >
            {navbarOpen ? <Hamburger open /> : <Hamburger />}
        </Toggle>
      </div>
      {navbarOpen ? (
        <Navbox>
            <NavbarLinks />
            <ChangeTheme showInMobile={false}/>
        </Navbox>
      ) : (
        <Navbox open>
            <NavbarLinks />
            <ChangeTheme showInMobile={false}/>
        </Navbox>
      )}
    </Navigation>
  )
}

export default Navbar