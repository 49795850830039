import * as React from "react"
import PropTypes from "prop-types"
import "./layout.css"

import { useStaticQuery, graphql } from "gatsby"
/** @jsx jsx */
import { Themed, jsx } from "theme-ui"

import Footer from "./footer";
// import Header from "./header"
import Navbar from "./Navbar/Navbar";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Themed.root>
      <>
        <Navbar siteTitle={data.site.siteMetadata?.title || `Title`} />
        {/* <Header siteTitle={data.site.siteMetadata?.title || `Title`} /> */}
        <div
          sx={{
            margin: `0 auto`,
            maxWidth: 800,
            padding: 0,
            paddingX: 3,
            paddingBottom: 3,
          }}
        >
          <main>{children}</main>
          <Footer/>
        </div>
      </>
    </Themed.root>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
