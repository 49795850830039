import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ImageLogo from "../../images/logo.png";

/** @jsx jsx */
import { jsx } from "theme-ui"

const Logo = ({ siteTitle }) => {
  return (
      <Link 
        to="/"
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: 'text',
          textDecoration: 'none',
          variant: 'styles.h1',
          lineHeight: 1,
          margin: 0,
          '@media (max-width: 768px)': {
            variant: 'styles.h2',
            lineHeight: 0.9,
            margin: 0,
          }
        }}
      >
        <img 
          sx={{
            width: '30px',
            margin: 'auto 0',
            marginRight: 2
          }}
          src={ImageLogo}
          alt="logo"
        />
        <span> {siteTitle} </span>
      </Link>
  )
}

export default Logo