import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"

const Navbox = ({ open, children }) => {
    return (
        <div sx={{
            display: 'flex',
            height: '100%',
            justifyContent: 'flex-end',
            alignItems: 'center',
          
            '@media (max-width: 768px)': {
              flexDirection: 'column',
              position: 'fixed',
              width: '100%',
              justifyContent: 'flex-start',
              paddingTop: '10vh',
              backgroundColor: 'background',
              transition: 'all 0.3s ease-in',
              top: '8vh',
              right: `${open ? "-100%" : "0"}`
            }
        }}>
            { children }
        </div>
    )
}

export default Navbox