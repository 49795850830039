import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons"
import { faGithub, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

/** @jsx jsx */
import { jsx } from "theme-ui"

const Footer = () => {
  return (
    <footer
        sx={{
            paddingTop: 3,
            marginTop: 5,
            variant: "layout.footer",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }}
    >
        <div>
            Feito com
            {` `}
            <FontAwesomeIcon icon={faHeart} size="xs"/>
            {` e `}
            <a 
                sx={{
                    variant: 'a.link'
                }} 
                href="https://www.gatsbyjs.com"
                target="_blank"
                rel="noopener noreferrer"
            >
                Gatsby
            </a>
        </div>

        <div sx={{ '> a': {
            marginLeft: 3,
        }}}>
            <a
                sx={{
                    variant: 'a.footer'
                }}
                href="http://github.com/vinicius3333/"
                target="_blank"
                rel="noopener noreferrer"
                title="Github"
            >
            <FontAwesomeIcon
                icon={faGithub} size="2x"
            />
            </a>
            <a
                sx={{
                    variant: 'a.footer'
                }}
                href="https://www.linkedin.com/in/vinícius3333/"
                target="_blank"
                rel="noopener noreferrer"
                title="Linkedin"
            >
            <FontAwesomeIcon
                icon={faLinkedinIn}
                size="2x"
            />
            </a>
        </div>
    </footer>
  )
}

export default Footer