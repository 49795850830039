import React from "react"
import { Link } from "gatsby"
import { Link as LinkThemed } from "theme-ui"

const NavbarLinks = () => {
  return (
    <>
        <LinkThemed key="sobre-mim" variant="button" as={Link} to="/sobre-mim">
            Sobre mim
        </LinkThemed>
        <LinkThemed key="blog" variant="button" as={Link} to="/blog">
            Blog
        </LinkThemed>
    </>
  )
}

export default NavbarLinks